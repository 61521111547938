import { useContext, useReducer } from "react"
import cx from "classnames"
import DeleteAllDirections from "./DeleteAllDirections"
import DeleteProjectSearch from "./DeleteProjectSearch"
import DeleteSearchRound from "./DeleteSearchRound"
import DownloadRoundNotice from "./DownloadRoundNotice"
import DownloadRound from "./DownloadRound"
import DuplicateProjectSearch from "./DuplicateProjectSearch"
import WonLostActions from "./WonLostActions"
import AddNewSearchRound from "./AddNewSearchRound"
import ViewInPortal from "./ViewInPortal"
import ToggleHighResDownload from "./ToggleHighResDownload"
import HideSearchRound from "./HideSearchRound"
import DropdownMenu from "./DropdownMenu"
import PlayAll from "./PlayAll"
import Ellipsis from "../../../shared/icons/Ellipsis"
import { ProjectSearchContext } from "./ProjectSearch"

const searchActionsReducer = (state, action) => {
  switch (action.type) {
    case "CLOSE_NOTICE":
      return {
        ...state,
        disableDownload: false,
        downloadNotice: false,
        downloadMessage: false,
      }
    case "BEGIN_ZIP":
      if (state.downloadProcessing) return state
      return { ...state, disableDownload: true }
    case "SHOW_NOTICE":
      return { ...state, downloadNotice: true }
    case "ZIP_PROCESSING":
      return { ...state, downloadProcessing: true, downloadMessage: action.message || undefined }
    case "ZIP_COMPLETE":
      return {
        ...state,
        downloadProcessing: false,
        downloadMessage: undefined,
        url: action.url,
      }
    case "ZIP_CANCELLED":
      return {
        ...state,
        disableDownload: false,
        downloadProcessing: false,
        downloadMessage: "Download was sucessfully cancelled",
      }
    case "ZIP_FAILED":
      return {
        ...state,
        downloadProcessing: false,
        downloadMessage:
          action.message ?? "Your download was taking too long to process. Please try again.",
      }
    default:
      throw new Error("Unexpected action")
  }
}

const ProjectSearchActions = ({ children, selectedRound }) => {
  const { id, internalUser, searchRounds, slug, status } = useContext(ProjectSearchContext)
  const [state, dispatch] = useReducer(searchActionsReducer, {
    downloadNotice: false,
    downloadProcessing: false,
    disableDownload: false,
    url: "",
  })

  const allowSearchRoundAction = selectedRound.length === 1
  const showDeleteDirections =
    allowSearchRoundAction && selectedRound[0] && selectedRound[0].directions.length > 1
  const showDownload = allowSearchRoundAction && selectedRound[0].searchRoundSongVersions.length > 0
  const showWonLost = status !== "active" || selectedRound.length > 1 || searchRounds.length === 1
  const showHighResDownload = selectedRound[0] && selectedRound[0].showHighResDownload

  const handleCloseNotice = () => dispatch({ type: "CLOSE_NOTICE" })

  return (
    <div className="actions">
      <div className="contain">
        {searchRounds.length > 1 && !state.downloadNotice ? (
          children
        ) : (
          <DownloadRoundNotice downloadState={state} handleCloseNotice={handleCloseNotice} />
        )}
        <div
          className={cx("search-round-actions", {
            "multiple-rounds": searchRounds.length > 1,
          })}
        >
          <ul className="actions-list">
            {internalUser && showWonLost && <WonLostActions projectSearchId={id} status={status} />}
            <PlayAll selectedRound={selectedRound} />
            {showDownload && (
              <DownloadRound
                disableDownload={state.disableDownload}
                selectedRound={selectedRound}
                dispatch={dispatch}
              />
            )}
            {internalUser && selectedRound[0] && (
              <DropdownMenu icon={<Ellipsis />}>
                <DuplicateProjectSearch projectSearchId={id} />
                <AddNewSearchRound projectSearchId={id} slug={slug} />
                {allowSearchRoundAction && <HideSearchRound selectedRound={selectedRound} />}
                {showDeleteDirections && <DeleteAllDirections selectedRound={selectedRound} />}
                {searchRounds.length > 1 && allowSearchRoundAction && (
                  <DeleteSearchRound selectedRound={selectedRound} slug={slug} />
                )}
                <ViewInPortal projectSearchId={id} />
                <DeleteProjectSearch projectSearchId={id} />
              </DropdownMenu>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProjectSearchActions
