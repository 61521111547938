import { Provider } from "./MultiStepFormContext"
import StepIndicator from "./StepIndicator"

//Must have multiple children components in order to work, OR you must wrap the component as such:
//<MultiStepForm>{[<Component key="title"/>]}</MultiStepForm>
//Note: Each step in the form will handle it's own validation.
const MultiStepForm = ({
  children,
  formState,
  currentStep,
  setCurrentStep,
  disableStepper = false,
}) => {
  const next = () => {
    setCurrentStep((curr) => Math.min(curr + 1, children.length - 1))
  }
  const prev = () => {
    setCurrentStep((curr) => Math.max(curr - 1, 0))
  }

  const renderCurrentStep = () => {
    return children[currentStep]
  }

  return (
    <Provider value={{ ...formState, next, prev, currentStep, setCurrentStep }}>
      {!disableStepper && <StepIndicator steps={children} />}
      <div className="step-container">{children && renderCurrentStep()}</div>
    </Provider>
  )
}

export default MultiStepForm
