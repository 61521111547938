import { useContext } from "react"
import FormContext from "./MultiStepFormContext"
import cx from "classnames"

const StepIndicator = ({ steps }) => {
  const { currentStep, setCurrentStep } = useContext(FormContext)
  const stepsMap = {}

  steps.forEach((step, idx) => {
    stepsMap[idx] = step.key
  })

  return (
    steps && (
      <section className="step-indicator">
        <ul className="steps-list">
          {steps.map((step, index) => {
            return (
              <li
                //Don't want users to make it to submit phase if each step hasn't be validated
                //onClick={() => setCurrentStep(index)}
                className={cx("step", currentStep === index && "active")}
                key={index}
              >
                {step.key}
              </li>
            )
          })}
        </ul>
      </section>
    )
  )
}

export default StepIndicator
