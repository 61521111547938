import React, { useEffect, useState } from "react"
import axios from "axios"
import cx from "classnames"
import DownloadIcon from "../../../icons/DownloadIcon"
import Modal from "../../../shared/components/Modal"
import modalStyles from "../../../shared/styles/modal.module.scss"
import { validateEmail } from "../../../shared/utils/helpers"

const DownloadRound = ({ disableDownload, selectedRound, dispatch }) => {
  let loop
  let timeoutId
  let previousPending = false
  const [isDropDownOpen, setIsDropdownOpen] = useState(false)
  const [isEmailModal, setIsEmailModal] = useState(false)
  //const [formEmail, setFormEmail] = useState("")
  //const [readableStream, setReadableStream] = useState(null)
  //const [abortController, setAbortController] = useState(null)
  //const abortSignal = useRef()

  // Save for when we want to handle wav zip process through streaming
  const stopDownloadStream = () => {
    try {
      if (abortController && readableStream) {
        dispatch({ type: "ZIP_CANCELLED" })
        readableStream.cancel()
        abortController.abort("User Cancelled Download Stream")
      }
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("AbortError: Fetch request aborted")
        return
      }
      console.log("Stream cancelled poorly: ", err)
    }
  }

  const checkDownloadResponse = ({ success, url, failure = false }) => {
    if (!success && !failure) {
      dispatch({ type: "ZIP_PROCESSING" })
      return
    }

    clearInterval(loop)
    clearTimeout(timeoutId)

    if (success) {
      dispatch({ type: "ZIP_COMPLETE", url })
    } else if (failure) {
      dispatch({
        type: "ZIP_FAILED",
        message: "Your download failed to be generated. Please try again later.",
      })
    }
  }

  const handleHighRes = () => {
    setIsEmailModal(true)
  }

  const handleEmailSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    if (validateEmail(form.get("email"))) {
      beginDownload(true, form.get("email"))
      setIsEmailModal(false)
    }
    return false
  }

  const handleCloseModal = (e) => {
    e.preventDefault()
    setIsEmailModal(false)
  }

  const beginDownload = (is_high_res = false, email = "") => {
    dispatch({ type: "BEGIN_ZIP" })
    setIsDropdownOpen(false)
    timeoutId = setTimeout(() => {
      clearInterval(loop)
      dispatch({ type: "ZIP_FAILED" })
    }, 300000) // kill the poll after 5 mins
    loop = setInterval(() => poll(is_high_res, email), 2000)
  }

  const poll = (is_high_res, email = "") => {
    if (previousPending) return

    const url = is_high_res
      ? `/api/v1/search_rounds/${selectedRound[0].id}/download_wav`
      : `/api/v1/search_rounds/${selectedRound[0].id}/download`

    const options = is_high_res ? { body: { email: email } } : {}
    previousPending = true
    axios
      .post(url, options)
      .then((response) => {
        dispatch({ type: "SHOW_NOTICE" })
        checkDownloadResponse(response.data)
        previousPending = false
      })
      .catch((error) => {
        Sentry.captureException(error)
        dispatch({ type: "ZIP_FAILED" })
        clearInterval(loop)
      })
  }

  // Save for when we want to handle wav zip process through streaming
  const downloadWav = async () => {
    if (window.safari) {
      alert("This feature is not supported by Safari")
      return
    }
    dispatch({ type: "BEGIN_ZIP" })
    let newController = new AbortController()
    setAbortController(newController)
    abortSignal.current = newController.signal

    // Open user's system file select dialogue box and set StreamBuffer destination
    const file_dest = await window
      .showSaveFilePicker({
        suggestedName: `${selectedRound[0].name}.zip`,
        types: [
          {
            description: "Zip File",
            accept: {
              "application/zip": [".zip"],
            },
          },
        ],
      })
      .then((handle) => handle.createWritable())
      .catch((err) => {
        dispatch({ type: "ZIP_CANCELLED" })
        console.log(err)
      })

    if (!file_dest) {
      dispatch({ type: "ZIP_CANCELLED" })
      return
    }

    dispatch({ type: "ZIP_PROCESSING" })
    dispatch({ type: "SHOW_NOTICE" })
    setIsDropdownOpen(false)

    let throt_func = _.throttle(
      function (charsReceived) {
        dispatch({
          type: "ZIP_PROCESSING",
          message: `Download in progress: ${(charsReceived / (1024 * 1024 * 1024)).toFixed(2)}/GBs`,
        })
      },
      500,
      { trailing: false }
    )

    try {
      const file_src = fetch(`/api/v1/search_rounds/${selectedRound[0].id}/download_zip`, {
        signal: abortSignal.current,
      })
        .then((response) => response.body)
        .catch((err) => {
          dispatch({ type: "ZIP_CANCELLED" })

          console.log("Fetch Catch err: ", err)
        })

      file_src.then(async (s) => {
        let charsReceived = 0
        const reader = s.getReader()
        setReadableStream(reader)
        reader.read().then(function processStream({ done, value }) {
          // Result objects contain two properties:
          // done  - true if the stream has already given you all its data.
          // value - some data. Always undefined when done is true.
          if (done) {
            //throt_func.cancel
            if (abortSignal.current.aborted) {
              dispatch({ type: "ZIP_CANCELLED" })
            } else {
              dispatch({ type: "ZIP_COMPLETE" })
              // Write to disc if successful
              file_dest.close()
            }

            return
          }

          // Track and display progress
          charsReceived += value.length
          throt_func(charsReceived)

          // Write data to file.
          //const chunk = value
          file_dest.write(value)

          // Continue Reading
          return reader.read().then(processStream)
        })
      })
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Download aborted.")
      } else {
        console.log("Error while processing stream:", err)
      }
      dispatch({ type: "ZIP_CANCELLED" })
    }
  }

  const handleOnClick = (e) => {
    if (
      // if not clicked in song download options menu
      !e.target.closest(["div.download-options-menu", ".download"])
    ) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    //document.body.addEventListener("click", handleOnClick)

    return () => {
      //document.body.removeEventListener("click", handleOnClick)
    }
  }, [])

  return (
    <>
      <button
        className={cx("action", "download", { disabled: disableDownload })}
        onClick={() => {
          setIsDropdownOpen(!isDropDownOpen)
        }}
        data-cy="downloadSearchRound"
      >
        {`Download ${selectedRound[0].name}`}
      </button>
      <div className={cx("download-options-menu", isDropDownOpen && "expanded")}>
        <li
          className={cx("action download mm-low-res desktop show", { disabled: disableDownload })}
          data-no-touch=""
        >
          <a target="_blank" onClick={() => beginDownload(false)}>
            <span className="label">Low Res (MP3)</span>
            <span className="icon">
              <DownloadIcon />
            </span>
          </a>
        </li>
        <li
          className={cx("action download mm-high-res desktop show", { disabled: disableDownload })}
          data-no-touch=""
        >
          <a target="_blank" onClick={() => handleHighRes()}>
            <span className="label">High Res (WAV/AIF)</span>
            <span className="icon">
              <DownloadIcon />
            </span>
          </a>
        </li>
      </div>
      {isEmailModal && (
        <Modal>
          <div className={modalStyles.container}>
            <div className={modalStyles.wrap} style={{ top: "20%" }}>
              <a data-prevent-default="" className={modalStyles.close} onClick={handleCloseModal}>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <g fill="#21928e" fillRule="evenodd">
                      <path
                        className="icon-border"
                        d="M14.9985746 28.9375653c-7.69742474 0-13.93613993-6.2396655-13.93613993-13.9370902 0-7.69837494 6.23966549-13.93804043 13.93613993-13.93804043 7.6993252 0 13.9380404 6.23966549 13.9380404 13.93804043 0 7.698375-6.2387152 13.9370902-13.9380404 13.9370902m0-28.9375653C6.71481517 0 0 6.71576547 0 15.0004751 0 23.2851848 6.71386487 30 14.9985746 30 23.2851848 30 30 23.2851848 30 15.0004751 30 6.71481517 23.2842345 0 14.9985746 0"
                      ></path>
                      <path d="M20.0802802 9.16293103l-5.0780173 5.07122847-5.07219824-5.07898709-.76713363.7661638 5.07122847 5.07995689-5.07898709 5.0712285.7661638.7671336 5.07995689-5.0702586 5.0702586 5.0780172.7671336-.7661638-5.0702586-5.0789871 5.0789871-5.07122842z"></path>
                    </g>
                  </svg>
                </span>
              </a>
              <div className={modalStyles.target}>
                <div className={modalStyles.downloadMessageContainer}>
                  <h2 className={modalStyles.hTwo}>Please provide your Email below</h2>
                  <p className={modalStyles.messageText}>Large file zip generation takes a while</p>
                  <p className={modalStyles.messageText}>
                    If you'd like to leave the page before the file is ready, we will send the
                    download link to the provided email!
                  </p>
                </div>
                <div style={{ margin: "0px 20px" }}>
                  <p style={{ fontSize: ".8rem", textAlign: "center", opacity: "60%" }}>
                    DISCLAMER: Search rounds with more than 70 songs will result in a large zip file
                    container with a higher likelihood of timing out. We are actively working on
                    improving the performance of this feature and look forward to removing this
                    disclaimer.
                  </p>
                </div>
              </div>
              <form
                style={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "400px",
                  gap: "20px",
                  marginBottom: "20px",
                }}
                onSubmit={handleEmailSubmit}
              >
                <input
                  style={{
                    fontSize: "1.6rem",
                  }}
                  type="text"
                  name="email"
                />
                <button style={{ margin: "auto", width: "100%", maxWidth: "200px" }} type="submit">
                  Begin Download
                </button>
              </form>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default DownloadRound
